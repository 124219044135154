import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import moment from "moment";
import React, { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ThemeContext } from "styled-components";
import { useDebounce } from "use-debounce";
import { getAlerts, postEditAlerts } from "../../services/alertNotifications";
import { deleteAlerts } from "../../services/editAlerts";
import { alertLogsTableDefaults, getAlertLogsTableSettings, saveAlertLogsTableSettings } from "../../services/localStorage";
import MoreIcon from "../../svgs/MoreIcon";
import { isAdmin, isReadOnly } from "../../util/checkRole";
import copyToClipboard from "../../util/copyToClipboard";
import downloadFile from "../../util/downloadFile";
import errToStr from "../../util/errToStr";
import { kegOrTracker, kegsOrTrackers } from "../../util/kegOrTracker";
import { getTableFilters } from "../../util/urlParamFilters";
import AssetTypeLabel from "../AssetTypeLabel";
import Badge from "../Badge";
import BulkUnresolveSensorAlerts from "../BulkUnresolveSensorAlerts";
import DeleteModal from "../DeleteModal";
import EditAlertModal from "../EditAlertModal";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import NewTable from "../NewTable";
import ResolveAlertsModal from "../ResolveAlertsModal";
import Tag from "../Tag";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

type Alert = {
  alertId: string;
  alertText: string;
  resolvedById: string;
  resolvedByName: string;
  resolvedDate: number;
  resolutionNote: string;
  timestamp: number;
  sentTo: string[];
  sensorId: string;
  placeName: string;
  placeId: string;
  assetTypeName: string;
  assetTypeColour: string;
  trackerTags: any[];
  placeGroup: string;
  placeGroupColour: string;
  placeTags: any[];
  eventAlertId: string;
};

const AlertsLogsTable: FC<{ sensorId?: string }> = ({ sensorId }) => {
  const { color, short_datetime, long_datetime } = useContext(ThemeContext);

  const [data, setData] = useState<Alert[]>([]);
  const [count, setCount] = useState<number>(0);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [sorting, setSorting] = useState<SortingState>([{ id: "timestamp", desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnFiltersDebounced] = useDebounce(columnFilters, 200);

  const [selectedAlert, setSelectedAlert] = useState<any>(undefined);
  const [selectedAlertId, setSelectedAlertId] = useState<string | undefined>(undefined);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: getAlertLogsTableSettings() ? getAlertLogsTableSettings().pageSize : 20,
  });

  const [editAlertModalOpen, setEditAlertModalOpen] = useState<boolean>(false);
  const [deleteAlertModalOpen, setDeleteAlertModalOpen] = useState<boolean>(false);

  const [resolveModalOpen, setResolveModalOpen] = useState<boolean>(false);
  const [resolveAllModalOpen, setResolveAllModalOpen] = useState<boolean>(false);
  const [unresolveAllModalOpen, setUnresolveAllModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const fetchData = () => {
    setDataLoading(true);
    setDataErr("");

    getAlerts(source, {
      pageIndex,
      pageSize,
      orderBy: sorting,
      filters: getTableFilters(columnFiltersDebounced),
    })
      .then((response: any) => {
        setData(response.data);
        setCount(response.count);
        setDataLoading(false);
      })
      .catch((err: any) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const handleUnresolve = (id: string) => {
    const body = {
      action: "unresolve",
      alertId: id,
    };

    postEditAlerts(source, body)
      .then((response: any) => {
        fetchData();
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          toast.error("Failed to unresolve alert");
        }
      });
  };

  const fetchCsv = (download: boolean) => {
    setDataLoading(true);
    setDataErr("");

    getAlerts(source, {
      pageIndex,
      pageSize,
      orderBy: sorting,
      filters: getTableFilters(columnFiltersDebounced),
    })
      .then((response: any) => {
        const csvData = formatDataToCsv(response.data);
        if (download) {
          downloadFile(
            stringify(csvData, {
              quoted: true,
              quoted_string: true,
            }),
            "text/csv;charset=utf-8",
            "Alert Logs.csv"
          );
        } else {
          copyToClipboard(
            stringify(csvData, {
              quoted: true,
              quoted_string: true,
            })
          );
          toast.info("Copied to Clipboard");
        }
        setDataLoading(false);
      })
      .catch((err: any) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const formatDataToCsv = (data: Alert[]) => {
    const headers = [
      "Timestamp",
      "Alert",
      "Status",
      "Resolved By",
      "Resolution Date",
      "Notes",
      "Sent To",
      "Tracker ID",
      "Place",
      "Asset Type",
      "Tracker Tags",
      "Place Type",
      "Place Tags",
    ];

    return [
      headers,
      ...data.map((row) => [
        row.timestamp ? moment.unix(row.timestamp).format(long_datetime) : "",
        row.alertText,
        row.resolvedById ? "Resolved" : "Unresolved",
        row.resolvedByName || "",
        row.resolvedDate ? moment.unix(row.resolvedDate).format(long_datetime) : "",
        row.resolutionNote || "",
        row.sentTo ? row.sentTo.join(", ") : "",
        row.sensorId,
        row.placeName,
        row.assetTypeName,
        row.trackerTags ? row.trackerTags.map((tag) => tag.name).join(", ") : "",
        row.placeGroup,
        row.placeTags ? row.placeTags.map((tag) => tag.name).join(", ") : "",
      ]),
    ];
  };

  const columns = React.useMemo<ColumnDef<Alert>[]>(
    () => [
      {
        id: "actions",
        header: "",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          const original = row.original;
          return (
            <Tooltip
              maxWidth="none"
              theme="binary-no-padding"
              content={
                <MenuList>
                  {!isReadOnly() ? (
                    <>
                      {!original.resolvedByName && (
                        <MenuButton
                          onClick={() => {
                            setSelectedAlert(original);
                            setResolveModalOpen(true);
                          }}
                        >
                          Resolve
                        </MenuButton>
                      )}
                      {original.eventAlertId && (
                        <MenuButton
                          onClick={() => {
                            setSelectedAlertId(original.eventAlertId);
                            setEditAlertModalOpen(true);
                          }}
                        >
                          Edit Alert
                        </MenuButton>
                      )}
                      {original.resolvedByName && (
                        <MenuButton onClick={() => handleUnresolve(original.alertId)} disabled={!isAdmin()}>
                          Unresolve (Admins only)
                        </MenuButton>
                      )}
                      {original.sensorId && (
                        <MenuButton
                          onClick={() => {
                            setSelectedAlert(original);
                            setResolveAllModalOpen(true);
                          }}
                          disabled={!isAdmin()}
                        >
                          Resolve all for this {kegOrTracker("Keg", "Tracker")} (Admins only)
                        </MenuButton>
                      )}
                      {original.sensorId && (
                        <MenuButton
                          onClick={() => {
                            setSelectedAlert(original);
                            setUnresolveAllModalOpen(true);
                          }}
                          disabled={!isAdmin()}
                        >
                          Unresolve all for this {kegOrTracker("Keg", "Tracker")} (Admins only)
                        </MenuButton>
                      )}
                      {original.eventAlertId && (
                        <DangerMenuButton
                          onClick={() => {
                            setSelectedAlertId(original.eventAlertId);
                            setDeleteAlertModalOpen(true);
                          }}
                          disabled={!isAdmin()}
                        >
                          Delete Alert (Admins only)
                        </DangerMenuButton>
                      )}
                    </>
                  ) : (
                    <>
                      {!original.resolvedByName ? (
                        <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                          <div
                            style={{
                              cursor: "not-allowed",
                              userSelect: "none",
                            }}
                          >
                            <MenuButton disabled={true}>Resolve</MenuButton>
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                          <div
                            style={{
                              cursor: "not-allowed",
                              userSelect: "none",
                            }}
                          >
                            <MenuButton disabled={true}>Unresolve</MenuButton>
                          </div>
                        </Tooltip>
                      )}
                      {original.sensorId && (
                        <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                          <div
                            style={{
                              cursor: "not-allowed",
                              userSelect: "none",
                            }}
                          >
                            <MenuButton disabled={true}>Resolve all for this {kegOrTracker("Keg", "Tracker")}</MenuButton>
                          </div>
                        </Tooltip>
                      )}
                      {original.sensorId && (
                        <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                          <div
                            style={{
                              cursor: "not-allowed",
                              userSelect: "none",
                            }}
                          >
                            <MenuButton disabled={true}>Unresolve all for this {kegOrTracker("Keg", "Tracker")}</MenuButton>
                          </div>
                        </Tooltip>
                      )}
                      {original.eventAlertId && (
                        <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                          <div
                            style={{
                              cursor: "not-allowed",
                              userSelect: "none",
                            }}
                          >
                            <MenuButton disabled={true}>Edit Alert</MenuButton>
                          </div>
                        </Tooltip>
                      )}
                      {original.eventAlertId && (
                        <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                          <div
                            style={{
                              cursor: "not-allowed",
                              userSelect: "none",
                            }}
                          >
                            <MenuButton disabled={true}>Delete Alert</MenuButton>
                          </div>
                        </Tooltip>
                      )}
                    </>
                  )}
                </MenuList>
              }
              interactive={true}
              touch={true}
              appendTo={document.body}
              trigger="click"
              placement="bottom-start"
            >
              <MoreIconContainer>
                <MoreIconSize>
                  <MoreIcon fill={color.font[2]} />
                </MoreIconSize>
              </MoreIconContainer>
            </Tooltip>
          );
        },
        size: 65,
        minSize: 65,
      },
      {
        header: "Timestamp",
        accessorKey: "timestamp",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRangeUnix",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Alert",
        accessorKey: "alertText",
        meta: {
          filterType: "string",
        },
        size: 250,
      },
      {
        header: "Status",
        accessorKey: "resolvedStatus",
        cell: (props: any) => (
          <Badge title={props.getValue() ? "Resolved" : "Unresolved"} background={props.getValue() ? color.success_dark[2] : color.danger_dark[2]}>
            {props.getValue() ? "Resolved" : "Unresolved"}
          </Badge>
        ),
        meta: {
          filterType: "select",
          selectKey: "value",
          selectOptions: [
            { value: true, label: "Resolved", colour: color.success_dark[2] },
            { value: false, label: "Unresolved", colour: color.danger_dark[2] },
          ],
        },
        size: 150,
      },
      {
        header: "Resolved By",
        accessorKey: "resolvedByName",
        meta: {
          filterType: "string",
        },
        size: 200,
      },
      {
        header: "Resolution Date",
        accessorKey: "resolvedDate",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue() as number).format(short_datetime) : ""),
        meta: {
          filterType: "dateRangeUnix",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Notes",
        accessorKey: "resolutionNote",
        meta: {
          filterType: "string",
        },
        size: 250,
      },
      {
        header: "Sent To",
        accessorKey: "sentTo",
        cell: (props: any) => {
          const recipients = props.getValue() as string[];
          return recipients ? (
            <div>
              {recipients.map((recipient, index) => (
                <Tag key={index} name={recipient} colour={color.primary[2]} />
              ))}
            </div>
          ) : null;
        },
        meta: {
          filterType: "stringList",
          filterKey: "sentTo",
        },
        enableSorting: false,
        size: 250,
      },
      {
        header: "Tracker ID",
        accessorKey: "sensorId",
        cell: (props: any) => (
          <Link title={props.getValue() as string} to={`/${kegsOrTrackers("kegs", "trackers")}/${props.getValue()}`}>
            {props.getValue()}
          </Link>
        ),
        meta: {
          filterType: "string",
        },
        size: 130,
      },
      {
        header: "Place",
        accessorKey: "placeName",
        cell: (props: any) => (
          <Link title={props.getValue() as string} to={`/places/${props.row.original.placeId}`}>
            {props.getValue()}
          </Link>
        ),
        meta: {
          filterType: "string",
        },
        size: 200,
      },
      {
        header: "Asset Type",
        accessorKey: "assetTypeName",
        cell: (props: any) => (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <AssetTypeLabel name={props.getValue() as string} colour={props.row.original.assetTypeColour} icon={props.row.original.assetTypeIcon} />
          </div>
        ),
        meta: {
          filterType: "string",
        },
        size: 160,
      },
      {
        header: `${kegOrTracker("Keg", "Tracker")} Tag`,
        accessorKey: "trackerTagName",
        cell: (props: any) => (
          <Tag
            key={props.row.original.trackerTagName}
            name={props.row.original.trackerTagName}
            description={props.row.original.trackerTagDescription}
            colour={props.row.original.trackerTagColour}
          />
        ),
        meta: {
          filterType: "autoComplete",
          filterKey: "trackerTagName",
          loadOptionsKey: "trackerTags",
        },
        size: 160,
      },
      {
        header: "Place Type",
        accessorKey: "placeGroup",
        cell: (props: any) => (
          <>
            <ColoredDot color={props.row.original.placeGroupColour} />
            <span title={props.getValue() as string}>{props.getValue()}</span>
          </>
        ),
        meta: {
          filterType: "string",
        },
        size: 160,
      },
      {
        header: "Place Tag",
        accessorKey: "placeTagName",
        cell: (props: any) => (
          <Tag
            key={props.row.original.placeTagName}
            name={props.row.original.placeTagName}
            description={props.row.original.placeTagDescription}
            colour={props.row.original.placeTagColour}
          />
        ),
        meta: {
          filterType: "autoComplete",
          filterKey: "placeTagName",
          loadOptionsKey: "placeTags",
        },
        size: 160,
      },
    ],
    [color]
  );

  return (
    <>
      <NewTable
        data={data}
        count={count}
        dataErr={dataErr}
        dataLoading={dataLoading}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        columnFiltersDebounced={columnFiltersDebounced}
        setColumnFilters={setColumnFilters}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        fetchData={fetchData}
        fetchCsv={fetchCsv}
        defaultTableSettings={alertLogsTableDefaults}
        getTableSettings={getAlertLogsTableSettings}
        saveTableSettings={saveAlertLogsTableSettings}
        dataTypeName="Alerts"
        emptyDataMsg="No alerts found"
      />
      {editAlertModalOpen && <EditAlertModal id={selectedAlertId} modalOpen={editAlertModalOpen} setModalOpen={setEditAlertModalOpen} />}
      {deleteAlertModalOpen && (
        <DeleteModal
          title="Delete Alert"
          body={<span>Are you sure you want to delete this alert?</span>}
          successMsg="Alert Deleted"
          onDelete={fetchData}
          onClose={() => setSelectedAlertId(undefined)}
          modalOpen={deleteAlertModalOpen}
          setModalOpen={setDeleteAlertModalOpen}
          deleteService={deleteAlerts}
          serviceParams={[selectedAlertId]}
        />
      )}
      {resolveModalOpen && (
        <ResolveAlertsModal
          alert={selectedAlert}
          resolveAllForTracker={false}
          setData={fetchData}
          modalOpen={resolveModalOpen}
          setModalOpen={setResolveModalOpen}
        />
      )}
      {resolveAllModalOpen && (
        <ResolveAlertsModal
          alert={selectedAlert}
          resolveAllForTracker={true}
          setData={fetchData}
          modalOpen={resolveAllModalOpen}
          setModalOpen={setResolveAllModalOpen}
        />
      )}
      {unresolveAllModalOpen && (
        <BulkUnresolveSensorAlerts alert={selectedAlert} onSuccess={fetchData} modalOpen={unresolveAllModalOpen} setModalOpen={setUnresolveAllModalOpen} />
      )}
    </>
  );
};

export default AlertsLogsTable;
