import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import matchSorter from "match-sorter";
import moment from "moment";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { TbDownload } from "react-icons/tb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { fetchAutoComplete } from "../../../services/autoComplete";
import { fetchFleetSnapshotReport } from "../../../services/fleetSnapshotReport";
import downloadFile from "../../../util/downloadFile";
import errToStr from "../../../util/errToStr";
import { printFixedTemp, printTempUnit } from "../../../util/formatUnits";
import getParameterByName from "../../../util/getParamByName";
import { kegsOrTrackers } from "../../../util/kegOrTracker";
import sortTags from "../../../util/sortTags";
import { DangerAlert } from "../../Alerts";
import AssetTypeLabel from "../../AssetTypeLabel";
import Badge from "../../Badge";
import Bold from "../../Bold";
import { PrimaryBtn } from "../../Buttons";
import FlatpickrRangePicker from "../../FlatpickrRangePicker";
import { ColoredDot } from "../../GlobalStyles/coloredDot";
import LoadingContainer from "../../LoadingContainer";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import { Subtitle, Title } from "../../PageTitle/styles";
import { ClearPanel, Panel } from "../../Panel";
import { TemperatureSpan } from "../../ReportsScreen/styles";
import Table from "../../Table";
import Tag from "../../Tag";

const formatDataToCsv = (tableRef: any, long_datetime: string) => {
  const headers = [
    kegsOrTrackers("Keg", "Tracker") + " ID",
    "Asset ID",
    "Asset Type",
    kegsOrTrackers("Keg Name", "Tracker Name"),
    kegsOrTrackers("Keg Tags", "Tracker Tags"),
    "Current Beer",
    "Current Place",
    "Current Place Type",
    "Current Place Date Arrived",
    "Last Date Filled",
    "Last Date On Tap",
    "Last Date Emptied",
    "Keg Empty Status",
    "Keg In Fridge Status",
    "Keg On Tap Status",
    "Keg Orientation",
    `Keg Temperature (${printTempUnit()})`,
    "Keg Freshness (%)",
    "Marked For Pickup",
  ];

  const sortedData = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...sortedData.map((row: any) => {
      return [
        row.binaryId,
        row.assetId,
        row.assetTypeName,
        row.trackerName,
        row.trackerTags
          ? row.trackerTags
              .sort(sortTags)
              .map((tag: any) => tag.name)
              .join(", ")
          : "",
        row.currentBeer,
        row.currentPlace,
        row.currentPlaceType,
        row.currentPlaceDateArrived ? moment(row.currentPlaceDateArrived).format(long_datetime) : "",
        row.lastDateFilled ? moment(row.lastDateFilled).format(long_datetime) : "",
        row.lastDateOnTap ? moment(row.lastDateOnTap).format(long_datetime) : "",
        row.lastDateEmptied ? moment(row.lastDateEmptied).format(long_datetime) : "",
        formatBoolean(row.kegEmptyStatus),
        formatBoolean(row.kegInFridgeStatus),
        formatBoolean(row.kegOnTapStatus),
        row.kegOrientation === "S" ? "Sideways " : row.kegOrientation === "D" ? "Upside Down " : "Upright ",
        row.kegTemperature ? printFixedTemp(row.kegTemperature, 1) : "",
        row.kegFreshness,
        formatBoolean(row.markedForPickup),
      ];
    }, []),
  ];
};

const formatBoolean = (value: boolean | null | undefined) => {
  if (value === true) return "True";
  if (value === false) return "False";
  return ""; // Or N/A, or leave blank
};

const FleetSnapshot: FC<any> = () => {
  const { color, short_datetime, short_date, long_datetime } = useContext(ThemeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const tableRef = useRef<any>(null);

  const dateParam = getParameterByName("date", location.search) != null ? getParameterByName("date", location.search) : null;

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  // const [filtersOpen, setFiltersOpen] = useState<boolean>(true);

  // // Report filters
  // const [date, setDate] = useState<any>(dateParam != null ? Number(dateParam) * 1000 : moment().subtract(7, "days").valueOf());

  // const [appliedFilters, setAppliedFilters] = useState<number>(0);

  const [filterOptions, setFilterOptions] = useState<any>({
    assetTypes: [],
    trackerTags: [],
    placeTypes: [],
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // useEffect(() => {
  //   const dateParam = getParameterByName("date", location.search) != null ? getParameterByName("date", location.search) : null;

  //   if (dateParam != null) {
  //     setFiltersOpen(false);
  //     fetchReport(Number(dateParam) * 1000);
  //   }
  // }, []);

  // // On location change (e.g. url parameter changes because filters changed), update filters and fetch report
  // useEffect(() => {
  //   let selectedDate = undefined;

  //   const dateParam = getParameterByName("date", location.search) != null ? getParameterByName("date", location.search) : null;
  //   if (dateParam != null) {
  //     selectedDate = Number(dateParam) * 1000;
  //     setDate(selectedDate);
  //   }

  //   fetchReport(selectedDate);
  // }, [location]);

  // const updateQueryParams = () => {
  //   const parsed = queryString.parse(location.search);
  //   const newQuery = { ...parsed };

  //   if (date != null) {
  //     newQuery["date"] = Math.round(date.valueOf() / 1000).toString();
  //   } else {
  //     delete newQuery["date"];
  //   }

  //   const stringified = queryString.stringify(newQuery);
  //   navigate({ ...location, search: stringified });
  // };

  // const fetchReport = (dateParam?: any) => {
  //   if (!date) setDataErr("Date is required");
  //   else {
  //     setDataLoading(true);
  //     setDataErr("");

  //     const dateArg = dateParam != null ? dateParam : date;

  //     fetchFleetSnapshotReport(source, dateArg ? Math.round(dateArg / 1000) : undefined)
  //       .then((response: any) => {
  //         // get distinct strings for use in the select input filters
  //         if (response.length > 0) {
  //           const assetTypes = new Set();
  //           const trackerTags = new Set();
  //           const placeTypes = new Set();

  //           for (let i = 0; i < response.length; i++) {
  //             if (response[i].assetTypeName) assetTypes.add(response[i].assetTypeName);
  //             if (response[i].currentPlaceType) placeTypes.add(response[i].currentPlaceType);
  //             if (response[i].trackerTags) response[i].trackerTags.forEach((tag: any) => trackerTags.add(tag.name));
  //           }

  //           setFilterOptions({
  //             assetTypes: Array.from(assetTypes).sort(),
  //             trackerTags: Array.from(trackerTags).sort(),
  //             placeTypes: Array.from(placeTypes).sort(),
  //           });
  //         }

  //         setData(response);
  //         setDataLoading(false);
  //         setFiltersOpen(false);
  //       })
  //       .catch((err) => {
  //         if (!axios.isCancel(err)) {
  //           setData([]);
  //           setDataErr(errToStr(err));
  //           setDataLoading(false);
  //         }
  //       });

  //     let filters = 0;

  //     if (dateArg != null) filters++;

  //     setAppliedFilters(filters);
  //   }
  // };

  useEffect(() => {
    setDataLoading(true);
    setDataErr("");

    const dateArg = Date.now();

    fetchFleetSnapshotReport(source, dateArg ? Math.round(dateArg / 1000) : undefined)
      .then((response: any) => {
        // get distinct strings for use in the select input filters
        if (response.length > 0) {
          const assetTypes = new Set();
          const trackerTags = new Set();
          const placeTypes = new Set();

          for (let i = 0; i < response.length; i++) {
            if (response[i].assetTypeName) assetTypes.add(response[i].assetTypeName);
            if (response[i].currentPlaceType) placeTypes.add(response[i].currentPlaceType);
            if (response[i].trackerTags) response[i].trackerTags.forEach((tag: any) => trackerTags.add(tag.name));
          }

          setFilterOptions({
            assetTypes: Array.from(assetTypes).sort(),
            trackerTags: Array.from(trackerTags).sort(),
            placeTypes: Array.from(placeTypes).sort(),
          });
        }

        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData([]);
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  }, []);

  const columns = [
    {
      id: "binaryId",
      Header: kegsOrTrackers("Keg", "Tracker"),
      accessor: "binaryId",
      Cell: (props: any) => {
        const url = kegsOrTrackers("kegs", "trackers");
        let title = props.value ? props.value : "";
        title += `${props.original.trackerName ? " (" + props.original.trackerName + ")" : ""}`;
        return (
          <Link to={`/${url}/${props.value}`} title={title}>
            {title}
          </Link>
        );
      },
      minWidth: 200,
      Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["binaryId", "trackerName"],
        }),
      filterAll: true,
    },
    {
      id: "assetId",
      Header: "Asset ID",
      accessor: "assetId",
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["assetId"],
        }),
      filterAll: true,
      minWidth: 150,
    },
    {
      id: "assetTypeId",
      Header: "Asset Type ID",
      accessor: "assetTypeId",
      show: false,
    },
    {
      id: "assetTypeName",
      Header: "Asset Type",
      accessor: "assetTypeName",
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") return true;
        if (filter.value === "none") return row[filter.id] === undefined;
        return row[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          <option value="none">None</option>
          {filterOptions.assetTypes.map((name: any) => {
            return (
              <option key={name} value={name}>
                {name}
              </option>
            );
          }, [])}
        </select>
      ),
      Cell: (props: any) => (
        <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
          <AssetTypeLabel name={props.value} colour={props.original.assetTypeColour} icon={props.original.assetTypeIcon} />
        </div>
      ),
      minWidth: 125,
    },
    {
      id: "trackerName",
      accessor: "trackerName",
      show: false,
    },
    {
      id: "trackerTags",
      Header: kegsOrTrackers("Keg Tags", "Tracker Tags"),
      accessor: "trackerTags",
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") return true;
        if (filter.value === "none") return !row[filter.id] || row[filter.id].length === 0;
        if (Array.isArray(row[filter.id])) return row[filter.id].some((tag: any) => tag.name === filter.value);
        return false;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          <option value="none">None</option>
          {filterOptions.trackerTags.map((tag: any) => {
            return (
              <option key={tag} value={tag}>
                {tag}
              </option>
            );
          }, [])}
        </select>
      ),
      Cell: (props: any) =>
        props.value ? (
          props.value.sort(sortTags).map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />)
        ) : (
          <></>
        ),
      minWidth: 140,
    },
    {
      id: "currentBeer",
      Header: "Current Beer",
      accessor: "currentBeer",
      minWidth: 150,
      maxWidth: 320,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["currentBeer"],
        }),
      filterAll: true,
    },
    {
      id: "currentPlace",
      Header: "Current Place",
      accessor: "currentPlace",
      minWidth: 320,
      maxWidth: 320,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["currentPlace"],
        }),
      filterAll: true,
      Cell: (props: any) => (props.original.currentPlaceId ? <Link to={`/places/${props.original.currentPlaceId}`}>{props.value}</Link> : <>{props.value}</>),
    },
    {
      id: "currentPlaceType",
      Header: "Place Type",
      accessor: "currentPlaceType",
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") return true;
        if (filter.value === "none") return !row[filter.id];
        return row[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          <option value="none">None</option>
          {filterOptions.placeTypes &&
            filterOptions.placeTypes.map((type: any) => {
              return (
                <option key={type} value={type}>
                  {type}
                </option>
              );
            })}
        </select>
      ),
      Cell: (props: any) => (
        <>
          <ColoredDot color={props.original.currentPlaceTypeColour} />
          <span title={props.value}>{props.value}</span>
        </>
      ),
      minWidth: 120,
    },
    {
      id: "currentPlaceDateArrived",
      Header: "Date Arrived",
      accessor: "currentPlaceDateArrived",
      filterMethod: (filter: any, row: any) => {
        if (!filter.value || filter.value.length === 0) return true;
        const rowDate = moment(row[filter.id]);
        const [startDate, endDate] = filter.value;
        return rowDate.isBetween(startDate, endDate, "day", "[]");
      },
      Filter: ({ onChange }: any) => (
        <FlatpickrRangePicker
          name="dateArrived"
          onClose={(d: any) => onChange(d)}
          options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date), maxDate: Date.now() }}
        />
      ),
      Cell: (props: any) => (props.value ? moment(props.value).format(short_datetime) : ""),
      minWidth: 150,
    },
    {
      id: "lastDateFilled",
      Header: "Last Filled",
      accessor: "lastDateFilled",
      filterMethod: (filter: any, row: any) => {
        if (!filter.value || filter.value.length === 0) return true;
        const rowDate = moment(row[filter.id]);
        const [startDate, endDate] = filter.value;
        return rowDate.isBetween(startDate, endDate, "day", "[]");
      },
      Filter: ({ onChange }: any) => (
        <FlatpickrRangePicker
          name="lastFilled"
          onClose={(d: any) => onChange(d)}
          options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date), maxDate: Date.now() }}
        />
      ),
      Cell: (props: any) => (props.value ? moment(props.value).format(short_datetime) : ""),
      minWidth: 150,
    },
    {
      id: "lastDateOnTap",
      Header: "Last On Tap",
      accessor: "lastDateOnTap",
      filterMethod: (filter: any, row: any) => {
        if (!filter.value || filter.value.length === 0) return true;
        const rowDate = moment(row[filter.id]);
        const [startDate, endDate] = filter.value;
        return rowDate.isBetween(startDate, endDate, "day", "[]");
      },
      Filter: ({ onChange }: any) => (
        <FlatpickrRangePicker
          name="lastOnTap"
          onClose={(d: any) => onChange(d)}
          options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date), maxDate: Date.now() }}
        />
      ),
      Cell: (props: any) => (props.value ? moment(props.value).format(short_datetime) : ""),
      minWidth: 150,
    },
    {
      id: "lastDateEmptied",
      Header: "Last Emptied",
      accessor: "lastDateEmptied",
      filterMethod: (filter: any, row: any) => {
        if (!filter.value || filter.value.length === 0) return true;
        const rowDate = moment(row[filter.id]);
        const [startDate, endDate] = filter.value;
        return rowDate.isBetween(startDate, endDate, "day", "[]");
      },
      Filter: ({ onChange }: any) => (
        <FlatpickrRangePicker
          name="lastEmptied"
          onClose={(d: any) => onChange(d)}
          options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date), maxDate: Date.now() }}
        />
      ),
      Cell: (props: any) => (props.value ? moment(props.value).format(short_datetime) : ""),
      minWidth: 150,
    },
    {
      id: "kegEmptyStatus",
      Header: "Empty",
      accessor: "kegEmptyStatus",
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") return true;
        if (filter.value === "true") return row[filter.id] === true;
        if (filter.value === "false") return row[filter.id] === false || row[filter.id] === null || row[filter.id] === undefined;
        return true;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      ),
      Cell: (props: any) => {
        if (props.value === null || props.value === undefined) return <></>;
        return (
          <Badge title={props.value ? "True" : "False"} background={props.value ? color.success_dark[2] : color.danger_dark[2]}>
            {props.value ? "True" : "False"}
          </Badge>
        );
      },
      minWidth: 80,
    },
    {
      id: "kegInFridgeStatus",
      Header: "In Fridge",
      accessor: "kegInFridgeStatus",
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") return true;
        if (filter.value === "true") return row[filter.id] === true;
        if (filter.value === "false") return row[filter.id] === false || row[filter.id] === null || row[filter.id] === undefined;
        return true;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      ),
      Cell: (props: any) => {
        if (props.value === null || props.value === undefined) return <></>;
        return (
          <Badge title={props.value ? "True" : "False"} background={props.value ? color.success_dark[2] : color.danger_dark[2]}>
            {props.value ? "True" : "False"}
          </Badge>
        );
      },
      minWidth: 90,
    },
    {
      id: "kegOnTapStatus",
      Header: "On Tap",
      accessor: "kegOnTapStatus",
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") return true;
        if (filter.value === "true") return row[filter.id] === true;
        if (filter.value === "false") return row[filter.id] === false || row[filter.id] === null || row[filter.id] === undefined;
        return true;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      ),
      Cell: (props: any) => {
        if (props.value === null || props.value === undefined) return <></>;
        return (
          <Badge title={props.value ? "True" : "False"} background={props.value ? color.success_dark[2] : color.danger_dark[2]}>
            {props.value ? "True" : "False"}
          </Badge>
        );
      },
      minWidth: 80,
    },
    {
      id: "kegOrientation",
      Header: "Orientation",
      accessor: "kegOrientation",
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") return true;
        return row[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          <option value="S">Sideways</option>
          <option value="D">Upside Down</option>
          <option value="U">Upright</option>
        </select>
      ),
      Cell: (props: any) => (props.value === "S" ? "Sideways" : props.value === "D" ? "Upside Down" : "Upright"),
      minWidth: 100,
    },
    {
      id: "kegTemperature",
      Header: "Temperature",
      accessor: "kegTemperature",
      Cell: (props: any) => (props.value != null ? <TemperatureSpan temp={props.value} /> : <></>),
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["kegTemperature"],
        }),
      filterAll: true,
      minWidth: 120,
    },
    {
      id: "kegFreshness",
      Header: "Freshness",
      accessor: "kegFreshness",
      Cell: (props: any) => (props.value != null ? `${props.value}%` : ""),
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["kegFreshness"],
        }),
      filterAll: true,
      minWidth: 100,
    },
    {
      id: "markedForPickup",
      Header: "Marked For Pickup",
      accessor: "markedForPickup",
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") return true;
        if (filter.value === "true") return row[filter.id] === true;
        if (filter.value === "false") return row[filter.id] === false || row[filter.id] === null || row[filter.id] === undefined;
        return true;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      ),
      Cell: (props: any) => {
        if (props.value === null || props.value === undefined) return <></>;
        return (
          <Badge title={props.value ? "True" : "False"} background={props.value ? color.success_dark[2] : color.danger_dark[2]}>
            {props.value ? "True" : "False"}
          </Badge>
        );
      },
      minWidth: 150,
    },
  ];

  const defaultSorted = [
    {
      id: "binaryId",
      desc: false,
    },
  ];

  const loadOptions = (inputName: string, inputValue: string, callback: any, includeAllOrgs = false) => {
    fetchAutoComplete(inputName, inputValue, undefined, includeAllOrgs).then((response) => {
      callback(response);
    });
  };

  return (
    <>
      {/* <Drawer
        placement="right"
        level={null}
        open={filtersOpen}
        onClose={() => {
          setFiltersOpen(false);
          updateQueryParams();
        }}
        onHandleClick={() => {
          setFiltersOpen(!filtersOpen);
        }}
        handler={false}
      >
        <DrawContent>
          <DrawHeader>
            <div
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                fontSize: "0",
                marginRight: "6px",
              }}
            >
              <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                <HiOutlineAdjustmentsHorizontal />
              </IconContext.Provider>
            </div>
            Filters
            <DrawCloseButton
              onClick={() => {
                setFiltersOpen(false);
              }}
              aria-label={`Close filters`}
            >
              <Cross />
            </DrawCloseButton>
          </DrawHeader>
          <DrawBody>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <label>Date</label>
              <FilterInputContainer>
                <FlatpickrPicker
                  name="date"
                  enableTime={true}
                  value={date}
                  clearable={false}
                  onChange={(d: any) => setDate(d[0])}
                  onClose={(d: any) => setDate(d[0])}
                  options={{ maxDate: Date.now() }}
                />
              </FilterInputContainer>
              {dataErr && <DangerAlert>{dataErr}</DangerAlert>}
            </form>
          </DrawBody>
          <DrawFooter>
            <PrimaryBtn
              onClick={() => {
                setFiltersOpen(false);
                updateQueryParams();
              }}
            >
              Apply Filters
            </PrimaryBtn>
          </DrawFooter>
        </DrawContent>
      </Drawer> */}
      <PageBreadcrumbs prevRoutes={[{ slug: "/reports", title: "Reports" }]} currRoute="Fleet Snapshot" />
      <PageContainer top="40px">
        <div style={{ position: "relative" }}>
          <LoadingContainer loading={dataLoading}>
            <ClearPanel style={{ textAlign: "center" }}>
              <Title style={{ marginBottom: "6px" }}>Fleet Snapshot</Title>
              <Subtitle>A snapshot of the fleet's status at a specific point in time</Subtitle>
            </ClearPanel>
            <ClearPanel style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>
                {/* <PrimaryBtn style={{ height: "40px", minWidth: "unset", marginRight: "12px" }} padding="0 6px" onClick={() => setFiltersOpen(!filtersOpen)}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div
                      style={{
                        display: "inline-block",
                        width: "20px",
                        height: "20px",
                        fontSize: "0",
                        marginRight: "6px",
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiOutlineAdjustmentsHorizontal />
                      </IconContext.Provider>
                    </div>
                    <span>Filters</span>
                  </div>
                </PrimaryBtn>
                <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                  {appliedFilters} filter{appliedFilters === 1 ? "" : "s"} applied
                </span> */}
              </div>
              <PrimaryBtn
                style={{ height: "40px", minWidth: "unset", marginRight: "12px" }}
                padding="0 6px"
                onClick={() =>
                  downloadFile(
                    stringify(formatDataToCsv(tableRef, long_datetime), {
                      quoted: true,
                      quoted_string: true,
                    }),
                    "text/csv;charset=utf-8",
                    "Fleet Snapshot Report.csv"
                  )
                }
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "inline-block",
                      width: "20px",
                      height: "20px",
                      fontSize: "0",
                      marginRight: "6px",
                    }}
                  >
                    <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                      <TbDownload />
                    </IconContext.Provider>
                  </div>
                  <span
                    style={{
                      marginRight: "6px",
                    }}
                  >
                    CSV
                  </span>
                </div>
              </PrimaryBtn>
            </ClearPanel>
            <Panel>
              <Table
                loading={dataLoading}
                style={{ clear: "both" }}
                data={data}
                filterable={true}
                columns={columns}
                defaultSorted={defaultSorted}
                ref={tableRef}
              />
            </Panel>
            {dataErr && <DangerAlert>{dataErr}</DangerAlert>}
          </LoadingContainer>
        </div>
      </PageContainer>
    </>
  );
};

export default FleetSnapshot;
