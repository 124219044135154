import axios from "axios";
import ReactEcharts from "echarts-for-react";
import moment from "moment";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import { getFreshnessOnTap } from "../../services/kegFreshnessOnTap";
import InfoIcon from "../../svgs/Legend";
import errToStr from "../../util/errToStr";
import ChartHeading from "../ChartHeading";
import LoadingContainer from "../LoadingContainer";
import InfoTooltip from "../Tooltip";
import { ChartContainer, DateText, InfoIconContainer } from "./styles";

const FreshnessOnTap: FC<any> = ({ filterDates }) => {
  const { color, color_50, echarts_theme, echarts_opacity, short_date, long_datetime } = useContext(ThemeContext);

  const [data, setData] = useState<any>({ fresh0: 0, fresh0to25: 0, fresh25to50: 0, fresh50to75: 0, fresh75to100: 0, total: 0 });
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const options = useRef<any>({});

  const [echartsInstance, setEchartsInstance] = useState<any>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    // If end date is undefined set to current date
    const dates = {
      start: filterDates && filterDates.start !== undefined ? filterDates.start.unix() : undefined,
      end: filterDates && filterDates.end !== undefined ? filterDates.end.unix() : moment().unix(),
    };

    getFreshnessOnTap(source, dates)
      .then((response) => {
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData([]);
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [filterDates]);

  const returnPercent = (value: number, total: number) => {
    const ratio = total > 0 ? value / total : 0;
    return ratio * 100;
  };

  const formatTooltip = (param: any) => {
    if (param) {
      let tooltip = `<span>${param.name}<br />`;
      tooltip += `${param.marker} ${param.value} (${returnPercent(param.value, data.total).toFixed(1)}%)`;
      tooltip += "</span>";
      return tooltip;
    }
  };

  const formatData = () => {
    if (data.fresh0 !== 0 || data.fresh0to25 !== 0 || data.fresh25to50 !== 0 || data.fresh50to75 !== 0 || data.fresh75to100 !== 0) {
      return [
        {
          name: "0% Fresh",
          value: data.fresh0,
          itemStyle: {
            color: color_50.worse[2],
            borderWidth: 1.5,
            borderColor: color.worse[2],
          },
        },
        {
          name: "0-25% Fresh",
          value: data.fresh0to25,
          itemStyle: {
            color: color_50.bad[2],
            borderWidth: 1.5,
            borderColor: color.bad[2],
          },
        },
        {
          name: "25-50% Fresh",
          value: data.fresh25to50,
          itemStyle: {
            color: color_50.average[2],
            borderWidth: 1.5,
            borderColor: color.average[2],
          },
        },
        {
          name: "50-75% Fresh",
          value: data.fresh50to75,
          itemStyle: {
            color: color_50.good[2],
            borderWidth: 1.5,
            borderColor: color.good[2],
          },
        },
        {
          name: "75-100% Fresh",
          value: data.fresh75to100,
          itemStyle: {
            color: color_50.great[2],
            borderWidth: 1.5,
            borderColor: color.great[2],
          },
        },
      ];
    }
  };

  useEffect(() => {
    if (echartsInstance) {
      options.current = {
        tooltip: {
          trigger: "item",
          formatter: formatTooltip,
        },
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            radius: ["45%", "65%"],
            avoidLabelOverlap: false,
            data: formatData(),
          },
        ],
      };

      // Updates the options of the chart each render
      // This is mainly to update the splitNumber on the bottom time axis
      if (options && options.current) {
        echartsInstance.setOption(options.current);
      }
    }
  }, [echartsInstance, data, echarts_opacity]);

  // listens for window resize events and triggers a chart resize so it fits
  // properly within it's container, fixes issue on iOS tablet when screen rotates
  const handleResizeEvent = useCallback(() => {
    echartsInstance.resize();
  }, [echartsInstance]);

  useEffect(() => {
    window.removeEventListener("resize", handleResizeEvent);

    if (echartsInstance) {
      window.addEventListener("resize", handleResizeEvent);
    }

    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, [echartsInstance]);

  return (
    <>
      <LoadingContainer loading={dataLoading} err={dataErr}>
        {!dataLoading && (
          <ChartContainer>
            <ChartHeading>Freshness On Tap</ChartHeading>
            {options && (
              <ReactEcharts
                key="freshnessOnTap"
                onChartReady={(chart: any) => setEchartsInstance(chart)}
                style={{ height: "100%", width: "100%" }}
                option={options.current}
                notMerge={true}
                lazyUpdate={true}
                theme={echarts_theme}
                opts={{ renderer: "svg" }}
              />
            )}
            <DateText style={{ textAlign: "left", marginTop: "6px" }} title={moment(filterDates.end).format(long_datetime)}>
              {filterDates.isRelative ? "Now" : `On ${moment(filterDates.end).format(short_date)}`}
            </DateText>
            <InfoTooltip content={`The most recent freshness of kegs on tap`} touch={true}>
              <InfoIconContainer>
                <InfoIcon fill={color.font[2]} />
              </InfoIconContainer>
            </InfoTooltip>
          </ChartContainer>
        )}
      </LoadingContainer>
    </>
  );
};

export default FreshnessOnTap;
