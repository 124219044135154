import axios, { CancelTokenSource } from "axios";

export const fetchFleetSnapshotReport = async (source: CancelTokenSource, date?: number) => {
  const params = {
    date,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/fleetsnapshotreport",
    params,
  });

  return data;
};
