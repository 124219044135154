import axios from "axios";
import ReactEcharts from "echarts-for-react";
import moment from "moment";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import { getTrackerDistributionHistory } from "../../services/trackerDistributionHistory";
import InfoIcon from "../../svgs/Legend";
import errToStr from "../../util/errToStr";
import hexToRgba from "../../util/hexToRgba";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import ChartHeading from "../ChartHeading";
import LoadingContainer from "../LoadingContainer";
import InfoTooltip from "../Tooltip";
import { ChartContainer, DateText, InfoIconContainer } from "./styles";

const TrackerDistributionHistory: FC<any> = ({ filterDates }) => {
  const { color, echarts_theme, short_date, long_datetime } = useContext(ThemeContext);

  const options = useRef<any>({});

  const [data, setData] = useState<any>([]);
  const [series, setSeries] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [echartsInstance, setEchartsInstance] = useState<any>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    // If end date is undefined set to current date
    const dates = {
      start: filterDates && filterDates.start !== undefined ? filterDates.start.unix() : undefined,
      end: filterDates && filterDates.end !== undefined ? filterDates.end.unix() : moment().unix(),
    };

    getTrackerDistributionHistory(source, dates)
      .then(({ data, series }: any) => {
        if (data) {
          const parsedData = data.map((row: any) => {
            const newRow = { ...row };
            Object.keys(row).forEach((key: any) => (newRow[key + " Percent"] = returnPercent(row[key], row["Total"])));
            return newRow;
          }, []);
          setData(parsedData);
          setSeries(series);
        }
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData([]);
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [filterDates]);

  const returnPercent = (value: number, total: number) => {
    const ratio = total > 0 ? value / total : 0;
    return ratio * 100;
  };

  const tooltipFormatter = (params: any) => {
    const tooltips: any = [];
    let tooltip = `<span>`;

    if (params.length > 0) {
      tooltip += `${params[0].axisValueLabel}<br />`;
      tooltip += `Total: ${params[0].value["Total"]}<br />`;

      for (let i = 0; i < params.length; i++) {
        const param = params[i];
        const value = param.value[param.seriesId] || 0;
        const percent = param.value[param.seriesId + " Percent"] || 0;
        const tooltipStr = `${param.marker} ${param.seriesName}: ${value} (${percent.toFixed(1)}%)<br />`;
        if (!tooltips.includes(tooltipStr)) tooltips.push(tooltipStr);
      }
    }

    tooltip += tooltips.reverse().join("") + "</span>";
    return tooltip;
  };

  const formatSeries = () => {
    if (series.length > 0) {
      return series.map(
        (s: any) => ({
          id: s.name,
          name: s.name,
          type: "line",
          stack: "stack",
          symbol: "none",
          yAxisIndex: 0,
          itemStyle: {
            color: hexToRgba(s.colour, s.empty ? 30 : 100),
          },
          areaStyle: {
            color: hexToRgba(s.colour, s.empty ? 30 : 80),
          },
          smooth: true,
          smoothMonotone: "x",
          connectNulls: true,
          encode: {
            x: "ts",
            y: s.name + " Percent",
          },
        }),
        []
      );
    }
  };

  const getDimensions = () => {
    if (data.length > 0) {
      return Object.keys(data[data.length - 1]);
    }
  };

  useEffect(() => {
    if (echartsInstance) {
      options.current = {
        dataset: {
          dimensions: getDimensions(),
          source: data,
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        tooltip: {
          trigger: "axis",
          transitionDuration: 0,
          axisPointer: {
            type: "cross",
            snap: true,
          },
          formatter: tooltipFormatter,
        },
        legend: {
          icon: "roundRect",
          type: "scroll",
          bottom: 0,
        },
        grid: {
          top: 10,
          right: 10,
          bottom: 30,
          left: 10,
          containLabel: true,
        },
        xAxis: [
          {
            type: "time",
            min: "dataMin",
            max: "dataMax",
            minInterval: 3600 * 24,
            axisLabel: {
              hideOverlap: true,
              formatter: (value: any): string => moment.unix(value).format(short_date),
            },
            axisPointer: {
              label: {
                formatter: ({ value }: any): string => moment.unix(value).format(short_date),
              },
            },
          },
        ],
        yAxis: [
          {
            min: 0,
            max: "dataMax",
            type: "value",
            position: "left",
            axisLabel: {
              formatter: (value: any): string => `${value.toFixed(0)}%`,
            },
            axisPointer: {
              label: {
                formatter: (params: any): string => `${params.value.toFixed(1)}%`,
              },
            },
            axisLine: {
              show: true,
            },
          },
        ],
        series: formatSeries(),
      };

      // Updates the options of the chart each render
      // This is mainly to update the splitNumber on the bottom time axis
      if (options && options.current) {
        echartsInstance.setOption(options.current);
      }
    }
  }, [echartsInstance, data]);

  // listens for window resize events and triggers a chart resize so it fits
  // properly within it's container, fixes issue on iOS tablet when screen rotates
  const handleResizeEvent = useCallback(() => {
    echartsInstance.resize();
  }, [echartsInstance]);

  useEffect(() => {
    window.removeEventListener("resize", handleResizeEvent);

    if (echartsInstance) {
      window.addEventListener("resize", handleResizeEvent);
    }

    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, [echartsInstance]);

  return (
    <LoadingContainer loading={dataLoading} err={dataErr}>
      {!dataLoading && (
        <ChartContainer>
          <ChartHeading>{kegsOrTrackers("Keg", "Tracker")} Distribution History</ChartHeading>

          {options && (
            <ReactEcharts
              key="current-durations-chart"
              onChartReady={(chart: any) => setEchartsInstance(chart)}
              style={{ height: "100%", width: "100%" }}
              option={options.current}
              notMerge={true}
              lazyUpdate={true}
              theme={echarts_theme}
              opts={{ renderer: "svg" }}
            />
          )}
          <DateText
            style={{ textAlign: "left", marginTop: "6px" }}
            title={`${moment(filterDates.start).format(long_datetime)} - ${moment(filterDates.end).format(long_datetime)}`}
          >
            {filterDates.isRelative
              ? filterDates.label
              : `From ${moment(filterDates.start).format(short_date)} to ${moment(filterDates.end).format(short_date)}`}
          </DateText>
          <InfoTooltip
            content={`For the selected date range, the  distribution of ${kegsOrTrackers("kegs", "trackers")} throughout different place types`}
            touch={true}
          >
            <InfoIconContainer>
              <InfoIcon fill={color.font[2]} />
            </InfoIconContainer>
          </InfoTooltip>
        </ChartContainer>
      )}
    </LoadingContainer>
  );
};

export default TrackerDistributionHistory;
