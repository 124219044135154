import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

type GetAlertsProps = {
  pageIndex?: number;
  pageSize?: number;
  orderBy?: any;
  filters?: any;
};
export const getAlerts = async (source: CancelTokenSource, { pageIndex, pageSize, orderBy, filters }: GetAlertsProps) => {
  const params: any = {
    count: true,
  };

  if (pageIndex !== undefined && pageSize !== undefined) params.skip = pageIndex * pageSize;

  if (pageSize !== undefined) params.top = pageSize;

  if (orderBy && orderBy.length > 0) params.orderby = orderBy.map((order: any) => `${order.id} ${order.desc ? "desc" : "asc"}`).join(", ");

  if (filters) params.filter = filters;

  const { data, headers } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + `/v1/alertnotifications`,
    params,
  });
  return { count: headers?.count, data };
};

export const countUnresolvedAlerts = async (source: CancelTokenSource, sensorId?: string) => {
  const params = {
    organisationId: getAccount().organisationId,
    sensorId,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/api/alertnotifications/unresolved/",
    params,
  });
  return data;
};

export const postEditAlerts = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/api/alertnotifications",
    data: JSON.stringify(body),
  });

  return data;
};

export const postEditBulkAlerts = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/api/alertnotifications/bulk",
    data: JSON.stringify(body),
  });

  return data;
};
