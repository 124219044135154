import moment from "moment";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { IoCalendarOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { DATE_RANGES, DateRange, formatDateLabel, parseDateRangeParams, updateDateRangeInUrl } from "../../util/dateRangeUtils";
import useWindowSize from "../../util/useWindowSize";
import { PrimaryBtn } from "../Buttons";
import DateRangeCalendarModal from "../DateRangeCalendarModal";
import Tooltip from "../Tooltip";
import { DateItem } from "./styles";

export type { DateRange } from "../../util/dateRangeUtils";

export interface DateRangeListProps {
  filterDates: DateRange;
  setFilterDates: (dateRange: DateRange) => void;

  // Configuration options
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  enableAllTime?: boolean;
  enableTime?: boolean;
  minWidth?: number;
}

// Static method to help parent components initialize from URL
export const getDateRangeFromUrl = (
  search: string,
  enableTime = false,
  shortDate = "MM/DD/YYYY",
  shortDatetime = "MM/DD/YYYY h:mm A",
  enableAllTime = false,
  minDate?: moment.Moment
): DateRange | null => {
  return parseDateRangeParams(enableTime, shortDate, shortDatetime, enableAllTime, minDate, search);
};

const DateRangeList: FC<DateRangeListProps> = ({
  filterDates,
  setFilterDates,
  minDate,
  maxDate,
  enableAllTime = false,
  enableTime = false,
  minWidth = undefined,
}) => {
  const { color, short_date, short_datetime } = useContext(ThemeContext);
  const { width } = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();

  // Track state changes to prevent loops
  const isStateChange = useRef(false);

  const [flatpickrModalOpen, setFlatpickrModalOpen] = useState<boolean>(false);
  const tooltipRef = useRef<any>(null);

  // Set the current date range and update URL parameters
  const setCurrentDateRange = (dateRange: DateRange, updateUrl = true) => {
    // Mark this as a state change to prevent URL change loop
    isStateChange.current = true;

    setFilterDates(dateRange);

    // Update URL if requested and we have navigation available
    if (updateUrl && navigate && location) {
      updateDateRangeInUrl(dateRange, enableAllTime, navigate, location);
    }

    // Reset the flag after a short delay to allow for the update to complete
    setTimeout(() => {
      isStateChange.current = false;
    }, 0);
  };

  // Handle URL parameter changes after initial render
  useEffect(() => {
    if (!location || isStateChange.current) return;

    // Only respond to URL changes (not our own updates)
    const urlParams = parseDateRangeParams(enableTime, short_date, short_datetime, enableAllTime, minDate, location.search);

    if (urlParams) {
      // Update state without triggering another URL update
      setCurrentDateRange(urlParams, false);
    }
  }, [location?.search]);

  // Update URL on component mount to ensure URL is in sync with state
  useEffect(() => {
    updateDateRangeInUrl(filterDates, enableAllTime, navigate, location);
  }, []);

  const handleDatesChange = (dates: any) => {
    const startMoment = dates.length > 0 ? moment(dates[0]) : filterDates.start;
    const endMoment = dates.length > 1 ? moment(dates[1]) : filterDates.end;
    const label = formatDateLabel(startMoment, endMoment, enableTime ? short_datetime : short_date);

    setCurrentDateRange({
      start: startMoment,
      end: endMoment,
      label: label,
    });
  };

  const handleDateItemClick = (rangeId: string) => {
    const rangeOption = DATE_RANGES.find((option) => option.id === rangeId);

    if (!rangeOption) {
      return;
    }

    const { start: startMoment, end: endMoment } = rangeOption.getDateRange();

    setCurrentDateRange({
      start: startMoment,
      end: endMoment,
      label: rangeOption.label,
    });

    if (tooltipRef.current) {
      tooltipRef.current.hide();
    }
  };

  const handleAllTimeClick = () => {
    const startMoment = minDate ? minDate : moment(0);
    const endMoment = moment();

    setCurrentDateRange({
      start: startMoment,
      end: endMoment,
      label: "All time",
    });

    if (tooltipRef.current) {
      tooltipRef.current.hide();
    }
  };

  const currentLabel = filterDates.label || formatDateLabel(filterDates.start, filterDates.end, enableTime ? short_datetime : short_date);

  return (
    <div style={{ float: "left" }}>
      <Tooltip
        onCreate={(instance) => (tooltipRef.current = instance)}
        content={
          <div
            style={{
              textAlign: "left",
              width: "200px",
              maxHeight: "250px",
              overflow: "auto",
            }}
          >
            <DateItem
              onClick={() => {
                setFlatpickrModalOpen(true);
                if (tooltipRef.current) {
                  tooltipRef.current.hide();
                }
              }}
            >
              <span>Custom</span>
            </DateItem>
            {DATE_RANGES.map((range) => (
              <DateItem key={range.id} onClick={() => handleDateItemClick(range.id)}>
                <span>{range.label}</span>
              </DateItem>
            ))}

            {enableAllTime && (
              <DateItem onClick={() => handleAllTimeClick()}>
                <span>All time</span>
              </DateItem>
            )}
          </div>
        }
        interactive={true}
        appendTo={document.body}
        trigger="click"
        touch={true}
        placement="bottom"
        theme="binary-no-padding"
      >
        <div>
          {/* Set tooltip trigger to manual to disable tooltip if > minWidth */}
          <Tooltip trigger={minWidth !== undefined && width < minWidth ? "mouseenter" : "manual"} content={currentLabel}>
            <PrimaryBtn style={{ height: "40px", margin: "0 6px", minWidth: "unset", display: "flex", alignItems: "center" }} padding="0px 12px">
              {minWidth !== undefined && width < minWidth ? "" : <span style={{ marginRight: "12px" }}>{currentLabel}</span>}
              <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                <IoCalendarOutline />
              </IconContext.Provider>
            </PrimaryBtn>
          </Tooltip>
        </div>
      </Tooltip>
      {flatpickrModalOpen && (
        <DateRangeCalendarModal
          start={filterDates.start}
          end={filterDates.end}
          minDate={minDate}
          maxDate={maxDate}
          handleDatesChange={handleDatesChange}
          modalOpen={flatpickrModalOpen}
          setModalOpen={setFlatpickrModalOpen}
          enableTime={enableTime}
        />
      )}
    </div>
  );
};

export default DateRangeList;
