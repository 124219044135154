import { DateTime } from "luxon";
import moment from "moment";
import isNumeric from "./isNumeric";

export const getTableFilters = (columnFilters: any) => {
  if (columnFilters.length > 0) {
    const andFilters: any = [];
    const orFilters: any = [];
    columnFilters.forEach(({ id, value }: { id: string; value: any }) => {
      if (value.type === "string" && value.value !== "") {
        // escape single quotes for odata query
        const searchStr = value.value.replace("'", "''");
        const filterKey = value?.filterKey ?? id;
        if (value.logic === "OR") orFilters.push(`contains(${filterKey}, '${searchStr}')`);
        else andFilters.push(`contains(${filterKey}, '${searchStr}')`);
      } else if (value.type === "number" && value.value !== "") {
        if (value.logic === "OR") orFilters.push(`${id} eq ${value.value}`);
        else andFilters.push(`${id} eq ${value.value}`);
      } else if (value.type === "select") {
        if (value?.selected) {
          const selectKey = value?.selectKey ?? "label";
          const searchStr = value.selected[selectKey];
          const filterKey = value?.filterKey ?? id;
          const comparisonOperator = value?.selected?.comparisonOperator ?? "eq";
          if (typeof searchStr === "string") {
            if (filterKey.includes(".")) {
              const keys = filterKey.split(".");
              if (value.logic === "OR") orFilters.push(`${keys[0]}/any(x: x/${keys[1]} ${comparisonOperator} '${searchStr.replace("'", "''")}')`);
              else andFilters.push(`${keys[0]}/any(x: x/${keys[1]} ${comparisonOperator} '${searchStr.replace("'", "''")}')`);
            } else {
              // escape single quotes for odata query
              if (value.logic === "OR") orFilters.push(`${filterKey} ${comparisonOperator} '${searchStr.replace("'", "''")}'`);
              else andFilters.push(`${filterKey} ${comparisonOperator} '${searchStr.replace("'", "''")}'`);
            }
          } else {
            if (filterKey.includes(".")) {
              const keys = filterKey.split(".");
              if (value.logic === "OR") orFilters.push(`${keys[0]}/any(x: x/${keys[1]} ${comparisonOperator} ${searchStr})`);
              else andFilters.push(`${keys[0]}/any(x: x/${keys[1]} ${comparisonOperator} ${searchStr})`);
            } else {
              if (value.logic === "OR") orFilters.push(`${filterKey} ${comparisonOperator} ${searchStr}`);
              else andFilters.push(`${filterKey} ${comparisonOperator} ${searchStr}`);
            }
          }
        }
      } else if (value.type === "inList" && value.value !== "") {
        if (value?.selected) {
          const selectKey = value?.selectKey ?? "label";
          const searchStr = value.selected[selectKey];
          if (typeof searchStr === "string") {
            // escape single quotes for odata query
            if (value.logic === "OR") orFilters.push(`'${searchStr.replace("'", "''")}' in ${id}`);
            else andFilters.push(`'${searchStr.replace("'", "''")}' in ${id}`);
          } else {
            if (value.logic === "OR") orFilters.push(`'${searchStr}' in ${id}`);
            else andFilters.push(`'${searchStr}' in ${id}`);
          }
        }
      } else if (value.type === "stringList" && value.value !== "") {
        const filterKey = value?.filterKey ?? "label";
        const searchStr = value.value;
        if (typeof searchStr === "string") {
          // escape single quotes for odata query
          if (value.logic === "OR") orFilters.push(`${filterKey}/any(x: contains(x, '${searchStr.replace("'", "''")}'))`);
          else andFilters.push(`${filterKey}/any(x: contains(x, '${searchStr.replace("'", "''")}'))`);
        }
        // TODO: Change all API date formats to ISO 8601 and then remove dateRangeUnix filters
      } else if (value.type === "dateRangeUnix") {
        const minMaxFilters = [];
        if (value.date[0]) minMaxFilters.push(`${id} ge ${moment(value.date[0]).startOf("day").unix()}`);
        if (value.date[1]) minMaxFilters.push(`${id} le ${moment(value.date[1]).endOf("day").unix()}`);
        if (minMaxFilters.length > 0) {
          if (value.logic === "OR") orFilters.push(`(${minMaxFilters.join(" and ")})`);
          else andFilters.push(`(${minMaxFilters.join(" and ")})`);
        }
      } else if (value.type === "dateRange") {
        const minMaxFilters = [];
        if (value.date[0]) minMaxFilters.push(`${id} ge ${DateTime.fromJSDate(value.date[0]).startOf("day").toUTC().toISO()}`);
        if (value.date[1]) minMaxFilters.push(`${id} le ${DateTime.fromJSDate(value.date[1]).endOf("day").toUTC().toISO()}`);
        if (minMaxFilters.length > 0) {
          if (value.logic === "OR") orFilters.push(`(${minMaxFilters.join(" and ")})`);
          else andFilters.push(`(${minMaxFilters.join(" and ")})`);
        }
      } else if (typeof value === "object") {
        const minMaxFilters = [];
        if (isNumeric(value[0])) minMaxFilters.push(`${id} ge ${value[0]}`);
        if (isNumeric(value[1])) minMaxFilters.push(`${id} le ${value[1]}`);
        if (minMaxFilters.length > 0) {
          if (value.logic === "OR") orFilters.push(`(${minMaxFilters.join(" and ")})`);
          else andFilters.push(`(${minMaxFilters.join(" and ")})`);
        }
      }
    });

    if (andFilters.length > 0 && orFilters.length > 0) {
      return `${andFilters.join(" and ")} and (${orFilters.join(" or ")})`;
    }

    if (andFilters.length > 0) {
      return andFilters.join(" and ");
    }

    if (orFilters.length > 0) {
      return orFilters.join(" or ");
    }
  }
};
