import moment from "moment";
import queryString from "query-string";

export interface DateRange {
  start: moment.Moment;
  end: moment.Moment;
  label: string;
  isRelative?: boolean; // Is it a relative date range (e.g. Last 24 hours not 2025-04-06 12:00:00 to 2025-04-07 12:00:00)
}

type DateRangeOption = {
  id: string;
  label: string;
  isRelative: boolean; // All predefined ranges are relative
  getDateRange: () => { start: moment.Moment; end: moment.Moment };
};

export const DATE_RANGES: DateRangeOption[] = [
  {
    id: "last-24-hours",
    label: "Last 24 hours",
    isRelative: true,
    getDateRange: () => ({ start: moment().subtract(24, "hours"), end: moment() }),
  },
  {
    id: "last-2-days",
    label: "Last 2 days",
    isRelative: true,
    getDateRange: () => ({ start: moment().subtract(2, "days"), end: moment() }),
  },
  {
    id: "last-3-days",
    label: "Last 3 days",
    isRelative: true,
    getDateRange: () => ({ start: moment().subtract(3, "days"), end: moment() }),
  },
  {
    id: "last-7-days",
    label: "Last 7 days",
    isRelative: true,
    getDateRange: () => ({ start: moment().subtract(7, "days"), end: moment() }),
  },
  {
    id: "last-2-weeks",
    label: "Last 2 weeks",
    isRelative: true,
    getDateRange: () => ({ start: moment().subtract(2, "weeks"), end: moment() }),
  },
  {
    id: "last-1-month",
    label: "Last month",
    isRelative: true,
    getDateRange: () => ({ start: moment().subtract(1, "months"), end: moment() }),
  },
  {
    id: "last-3-months",
    label: "Last 3 months",
    isRelative: true,
    getDateRange: () => ({ start: moment().subtract(3, "months"), end: moment() }),
  },
  {
    id: "last-6-months",
    label: "Last 6 months",
    isRelative: true,
    getDateRange: () => ({ start: moment().subtract(6, "months"), end: moment() }),
  },
  {
    id: "last-12-months",
    label: "Last year",
    isRelative: true,
    getDateRange: () => ({ start: moment().subtract(12, "months"), end: moment() }),
  },
];

export const formatDateLabel = (start: moment.Moment, end: moment.Moment, dateFormat: string): string => {
  return `${start.format(dateFormat)} to ${end.format(dateFormat)}`;
};

const parseDynamicRange = (rangeId: string): { amount: number; unit: moment.DurationInputArg2 } | null => {
  // Match patterns like "last-5-days", "last-2-weeks", "last-6-months", etc.
  const regex = /^last-(\d+)-(hours|days|weeks|months|years)$/;
  const match = rangeId.match(regex);

  if (match) {
    const amount = parseInt(match[1], 10);
    const unit = match[2] as moment.DurationInputArg2;
    return { amount, unit };
  }

  return null;
};

export const getDateRangeById = (rangeId: string): DateRange | null => {
  // Check if it matches our predefined ranges
  const predefinedRange = DATE_RANGES.find((range) => range.id === rangeId);
  if (predefinedRange) {
    const { start, end } = predefinedRange.getDateRange();
    return {
      start,
      end,
      label: predefinedRange.label,
      isRelative: predefinedRange.isRelative,
    };
  }

  // Check if it's a dynamic range
  const dynamicRange = parseDynamicRange(rangeId);
  if (dynamicRange) {
    const { amount, unit } = dynamicRange;
    return {
      start: moment().subtract(amount, unit),
      end: moment(),
      label: `Last ${amount} ${unit}`,
      isRelative: true, // Dynamic ranges are always relative
    };
  }

  return null;
};

export const getDefaultDateRange = (rangeId: string): DateRange => {
  const dateRange = getDateRangeById(rangeId);

  if (dateRange) {
    return dateRange;
  }

  // Fallback default
  return {
    start: moment().subtract(7, "days"),
    end: moment(),
    label: "Last 7 days",
    isRelative: true,
  };
};

export const parseDateRangeParams = (
  enableTime: boolean,
  short_date: string,
  short_datetime: string,
  enableAllTime: boolean,
  minDate?: moment.Moment,
  locationSearch?: string
): DateRange | null => {
  const parsed = queryString.parse(locationSearch || location.search);

  // First check if there's a range parameter
  if (parsed.range) {
    const rangeId = parsed.range as string;

    // Handle custom range with saved timestamps
    if (rangeId === "custom" && parsed.start && parsed.end) {
      const start = moment.unix(Number(parsed.start));
      const end = moment.unix(Number(parsed.end));
      const label = formatDateLabel(start, end, enableTime ? short_datetime : short_date);
      return {
        start,
        end,
        label,
        isRelative: false, // Custom date ranges are never relative
      };
    }
    // Handle all-time case
    else if (rangeId === "all-time" && enableAllTime) {
      const start = minDate ? minDate : moment(0);
      const end = moment();
      return {
        start,
        end,
        label: "All time",
        isRelative: true, // All time is relative to now
      };
    }
    // Handle predefined or dynamic range
    else {
      const dateRange = getDateRangeById(rangeId);
      if (dateRange) {
        return dateRange;
      }
    }
  }

  // Then check for standalone start and end parameters (backward compatibility)
  else if (parsed.start && parsed.end) {
    const start = moment.unix(Number(parsed.start));
    const end = moment.unix(Number(parsed.end));
    const label = formatDateLabel(start, end, enableTime ? short_datetime : short_date);
    return {
      start,
      end,
      label,
      isRelative: false, // Direct timestamp ranges are never relative
    };
  }

  return null;
};

export const formatDateRangeForUrl = (dateRange: DateRange, enableAllTime: boolean): { range: string; start?: number; end?: number } => {
  // Check if this is a predefined range
  const predefinedRange = DATE_RANGES.find((range) => range.label === dateRange.label);

  if (predefinedRange) {
    return { range: predefinedRange.id };
  }

  // Check if this is "All time"
  if (dateRange.label === "All time" && enableAllTime) {
    return { range: "all-time" };
  }

  // Check if this is a dynamic range (e.g., "Last 5 weeks")
  // Parse the label for patterns like "Last X unit"
  if (dateRange.isRelative && dateRange.label) {
    const dynamicMatch = dateRange.label.match(/^Last\s+(\d+)\s+(hour|day|week|month|year)s?$/i);
    if (dynamicMatch) {
      const amount = dynamicMatch[1];
      const unit = dynamicMatch[2].toLowerCase();

      // Convert to hyphenated format: last-X-units
      return { range: `last-${amount}-${unit}s` };
    }
  }

  // Otherwise treat as custom range
  return {
    range: "custom",
    start: dateRange.start.unix(),
    end: dateRange.end.unix(),
  };
};

// Update URL query string with date range parameters
export const updateDateRangeInUrl = (dateRange: DateRange, enableAllTime: boolean, navigate: any, location: any): void => {
  const parsed = queryString.parse(location.search);
  const dateRangeParams = formatDateRangeForUrl(dateRange, enableAllTime);

  // Remove old params if they exist
  delete parsed.label;
  delete parsed["is-now"];

  // If we're using a predefined range (not custom), also remove standalone start/end params
  // This ensures that old start/end params don't persist when switching to a predefined range
  if (dateRangeParams.range !== "custom") {
    delete parsed.start;
    delete parsed.end;
  }

  const newQuery = {
    ...parsed,
    ...dateRangeParams,
  };

  const stringified = queryString.stringify(newQuery);
  navigate({ ...location, search: stringified });
};
