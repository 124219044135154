import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ThemeContext } from "styled-components";
import AlertLogsTable from "../AlertLogsTable";
import AlertsTable from "../AlertsTable";
import DateRangeList, { DateRange, getDateRangeFromUrl } from "../DateRangeList";
import { TableHeaderButtons } from "../NewTable/styles";
import { PageContainer } from "../PageStyles";
import { Panel } from "../Panel";

const AlertsScreen: FC<any> = () => {
  const location = useLocation();
  const { short_date, short_datetime } = useContext(ThemeContext);

  // Initialize from URL params or use default
  const initialDateRange = getDateRangeFromUrl(
    location.search,
    false, // enableTime
    short_date,
    short_datetime,
    true // enableAllTime
  ) || {
    start: moment().subtract(7, "days"),
    end: moment(),
    label: "Last 7 days",
    isRelative: true,
  };

  const [filterDates, setFilterDates] = useState<DateRange>(initialDateRange);

  // Update filterDates when URL parameters change to trigger a re-fetch of data
  useEffect(() => {
    if (location) {
      const urlParams = getDateRangeFromUrl(location.search, false, short_date, short_datetime, true);

      if (urlParams) {
        setFilterDates(urlParams);
      }
    }
  }, [location.search]);

  return (
    <>
      <Tabs>
        <TabList className="react-tabs__tab-list__background_no-margin">
          <Tab>Alert Notifications</Tab>
          <Tab>Alerts</Tab>
        </TabList>
        <TabPanel>
          <PageContainer>
            <Panel>
              <AlertLogsTable />
            </Panel>
          </PageContainer>
        </TabPanel>
        <TabPanel>
          <PageContainer>
            <Panel>
              <AlertsTable />
            </Panel>
          </PageContainer>
        </TabPanel>
      </Tabs>
    </>
  );
};

export default AlertsScreen;
