import moment from "moment";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import LoadingContainer from "../LoadingContainer";
import { BoxElement, BoxesContainer, ContentContainer, Footer, Header, Icon, IconContainer, Value, ValueContainer } from "./styles";
//@ts-ignore
import "@zendeskgarden/react-buttons/dist/styles.css";
import axios, { CancelTokenSource } from "axios";
import { IconContext } from "react-icons";
import { BsThermometerHalf } from "react-icons/bs";
import { FaBell, FaTruck } from "react-icons/fa";
import { HiClock, HiSparkles } from "react-icons/hi";
import { IoIosBeer } from "react-icons/io";
import { MdChecklist } from "react-icons/md";
import { ThemeContext } from "styled-components";
import { fetchPlaceMetrics } from "../../services/placeMetrics";
import BbKeg from "../../svgs/BbKeg";
import FillsKegsIcon from "../../svgs/FillsKegsIcon";
import SensorIcon from "../../svgs/SensorIcon";
import TapIcon from "../../svgs/TapIcon";
import { isBinaryBeer } from "../../util/checkDomain";
import errToStr from "../../util/errToStr";
import { printTemp } from "../../util/formatUnits";
import { humaniseHours } from "../../util/humaniseDurations";
import AssetTypeLabel from "../AssetTypeLabel";
import TrackerListModal from "../TrackerListModal";

const PlaceMetrics: FC<any> = ({ id, filterDates, meta }) => {
  const { color, color_50, short_date, long_datetime } = useContext(ThemeContext);

  const [data, setData] = useState<any>({
    trackersHere: 0,
    assetTypesHere: [],
    productsHere: [],
    avgTemperature: undefined,
    avgFreshness: undefined,
    onTap: { count: 0, capacity: 0 },
    consumed: { count: 0, capacity: 0 },
    productsFilledHere: 0,
    kegsFilledHere: 0,
    avgVisitDuration: undefined,
    trackersArrived: 0,
    trackersDeparted: 0,
    pickupRequests: 0,
    alerts: { resolved: 0, unresolved: 0 },
    allocations: { allocated: 0, misallocated: 0 },
  });
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [trackerList, setTrackerList] = useState<any>([]);
  const [trackerListTitle, setTrackerListTitle] = useState<any>("");
  const [trackerListModalOpen, setTrackerListModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const fetchMetrics = useCallback(() => {
    // Only use the end date if it's not now as the back-end will use the current time
    // for now and can return the data more quickly by using LatestSample in this case
    const dates = {
      start: filterDates && filterDates.start !== undefined ? filterDates.start.unix() : undefined,
      end: filterDates && filterDates.end !== undefined && !filterDates.isRelative ? filterDates.end.unix() : undefined,
    };

    setDataLoading(true);
    setDataErr("");

    fetchPlaceMetrics(source, id, dates)
      .then((response) => {
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  }, [id, filterDates]);

  useEffect(() => {
    fetchMetrics();
  }, [id, filterDates, fetchMetrics]);

  return (
    <>
      <LoadingContainer loading={dataLoading} err={dataErr}>
        <BoxesContainer>
          <BoxElement style={{ display: "flex", flexDirection: "column", flexBasis: isBinaryBeer() ? "49%" : "100%" }}>
            <ContentContainer>
              <IconContainer>
                <Icon>
                  <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                    {isBinaryBeer() ? (
                      <BbKeg colour={color_50.font_bold[2]} size="32px" />
                    ) : (
                      <SensorIcon fill={color_50.font_bold[2]} width="32px" height="32px" />
                    )}
                  </IconContext.Provider>
                </Icon>
              </IconContainer>
              <ValueContainer>
                <Header>{isBinaryBeer() ? "Kegs Here" : "Trackers Here"}</Header>
                <Value>{data.trackersHere}</Value>
                <Footer title={moment(filterDates.end).format(long_datetime)}>
                  {filterDates.isRelative ? "Now" : `On ${moment(filterDates.end).format(short_date)}`}
                </Footer>
              </ValueContainer>
            </ContentContainer>
            {!isBinaryBeer() && data.assetTypesHere.length > 0 && (
              <ContentContainer
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginTop: "3px",
                }}
              >
                <Footer style={{ display: "flex", flexDirection: "row", gap: "6px", flexWrap: "wrap" }}>
                  {data.assetTypesHere.map((x: any) => (
                    <span
                      key={x.assetType.name}
                      style={{ display: "flex", textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => {
                        setTrackerList(x.trackers);
                        setTrackerListTitle(x.assetType.name);
                        setTrackerListModalOpen(true);
                      }}
                    >
                      <AssetTypeLabel name={x.assetType.name} colour={x.assetType.colour} icon={x.assetType.icon} count={x.trackers.length} />
                    </span>
                  ))}
                </Footer>
              </ContentContainer>
            )}
          </BoxElement>
          {isBinaryBeer() && data.productsHere.length > 0 && (
            <BoxElement>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                      <IoIosBeer />
                    </IconContext.Provider>
                  </Icon>
                </IconContainer>
                <ValueContainer>
                  <Header>Products Here</Header>
                  <Value>{data.productsHere.length}</Value>
                  <Footer title={moment(filterDates.end).format(long_datetime)}>
                    {filterDates.isRelative ? "Now" : `On ${moment(filterDates.end).format(short_date)}`}
                  </Footer>
                </ValueContainer>
              </ContentContainer>
            </BoxElement>
          )}
          {data.avgTemperature !== undefined && (
            <BoxElement>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                      <BsThermometerHalf />
                    </IconContext.Provider>
                  </Icon>
                </IconContainer>
                <ValueContainer>
                  <Header>Average {isBinaryBeer() ? "Keg" : "Tracker"} Temperature</Header>
                  <Value>{printTemp(data.avgTemperature)}</Value>
                  <Footer title={moment(filterDates.end).format(long_datetime)}>
                    {filterDates.isRelative ? "Now" : `On ${moment(filterDates.end).format(short_date)}`}
                  </Footer>
                </ValueContainer>
              </ContentContainer>
            </BoxElement>
          )}
          {isBinaryBeer() && data.avgFreshness !== undefined && (
            <BoxElement>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                      <HiSparkles />
                    </IconContext.Provider>
                  </Icon>
                </IconContainer>
                <ValueContainer>
                  <Header>Average Keg Freshness</Header>
                  <Value>{data.avgFreshness}%</Value>
                  <Footer title={moment(filterDates.end).format(long_datetime)}>
                    {filterDates.isRelative ? "Now" : `On ${moment(filterDates.end).format(short_date)}`}
                  </Footer>
                </ValueContainer>
              </ContentContainer>
            </BoxElement>
          )}
          {isBinaryBeer() && meta.emptiesBeerKegs && data.onTap.count > 0 && (
            <BoxElement>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    <TapIcon width="32" height="32" fill={color_50.font_bold[2]} />
                  </Icon>
                </IconContainer>
                <ValueContainer>
                  <Header>Kegs on Tap</Header>
                  <Value>
                    {data.onTap.count} {data.onTap.count === 1 ? "Keg" : "Kegs"} ({data.onTap.capacity}L)
                  </Value>
                  <Footer title={moment(filterDates.end).format(long_datetime)}>
                    {filterDates.isRelative ? "Now" : `On ${moment(filterDates.end).format(short_date)}`}
                  </Footer>
                </ValueContainer>
              </ContentContainer>
            </BoxElement>
          )}
          {isBinaryBeer() && meta.emptiesBeerKegs && data.consumed.count > 0 && (
            <BoxElement>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    <TapIcon width="32" height="32" fill={color_50.font_bold[2]} />
                  </Icon>
                </IconContainer>
                <ValueContainer>
                  <Header>Kegs Consumed</Header>
                  <Value>
                    {data.consumed.count} {data.consumed.count === 1 ? "Keg" : "Kegs"} ({data.consumed.capacity}L)
                  </Value>
                  <Footer title={`${moment(filterDates.start).format(long_datetime)} - ${moment(filterDates.end).format(long_datetime)}`}>
                    {filterDates.isRelative
                      ? filterDates.label
                      : `From ${moment(filterDates.start).format(short_date)} to ${moment(filterDates.end).format(short_date)}`}
                  </Footer>
                </ValueContainer>
              </ContentContainer>
            </BoxElement>
          )}
          {/* {isBinaryBeer() && data.consumed.count > 0 && (
            <BoxElement>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    <TapIcon width="32" height="32" fill={color_50.font_bold[2]} />
                  </Icon>
                </IconContainer>
                <ValueContainer>
                  <Header>Days Until Next Dry Tap</Header>
                  <Value>{"4 Days (12L/Day)"}</Value>
                  <Footer title={moment(filterDates.end).format(long_datetime)}>
                    {filterDates.isRelative ? "Now" : `On ${moment(filterDates.end).format(short_date)}`}
                  </Footer>
                </ValueContainer>
              </ContentContainer>
            </BoxElement>
          )} */}
          {isBinaryBeer() && meta.fillsBeerKegs && data.productsFilledHere > 0 && (
            <BoxElement>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    <FillsKegsIcon width="32" height="32" fill={color_50.font_bold[2]} />
                  </Icon>
                </IconContainer>
                <ValueContainer>
                  <Header>Products Filled Here</Header>
                  <Value>
                    {data.productsFilledHere} {data.productsFilledHere === 1 ? "Product" : "Products"}
                  </Value>
                  <Footer title={`${moment(filterDates.start).format(long_datetime)} - ${moment(filterDates.end).format(long_datetime)}`}>
                    {filterDates.isRelative
                      ? filterDates.label
                      : `From ${moment(filterDates.start).format(short_date)} to ${moment(filterDates.end).format(short_date)}`}
                  </Footer>
                </ValueContainer>
              </ContentContainer>
            </BoxElement>
          )}
          {isBinaryBeer() && meta.fillsBeerKegs && data.kegsFilledHere > 0 && (
            <BoxElement>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    <FillsKegsIcon width="32" height="32" fill={color_50.font_bold[2]} />
                  </Icon>
                </IconContainer>
                <ValueContainer>
                  <Header>Kegs Filled Here</Header>
                  <Value>
                    {data.kegsFilledHere} {data.kegsFilledHere === 1 ? "Keg" : "Kegs"}
                  </Value>
                  <Footer title={`${moment(filterDates.start).format(long_datetime)} - ${moment(filterDates.end).format(long_datetime)}`}>
                    {filterDates.isRelative
                      ? filterDates.label
                      : `From ${moment(filterDates.start).format(short_date)} to ${moment(filterDates.end).format(short_date)}`}
                  </Footer>
                </ValueContainer>
              </ContentContainer>
            </BoxElement>
          )}
          {data.avgVisitDuration !== undefined && (
            <BoxElement>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                      <HiClock />
                    </IconContext.Provider>
                  </Icon>
                </IconContainer>
                <ValueContainer>
                  <Header>Average Visit Duration</Header>
                  <Value>{humaniseHours(data.avgVisitDuration)}</Value>
                  <Footer title={`${moment(filterDates.start).format(long_datetime)} - ${moment(filterDates.end).format(long_datetime)}`}>
                    {filterDates.isRelative
                      ? filterDates.label
                      : `From ${moment(filterDates.start).format(short_date)} to ${moment(filterDates.end).format(short_date)}`}
                  </Footer>
                </ValueContainer>
              </ContentContainer>
            </BoxElement>
          )}
          <BoxElement>
            <ContentContainer>
              <IconContainer>
                <Icon>
                  <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                    <FaTruck />
                  </IconContext.Provider>
                </Icon>
              </IconContainer>
              <ValueContainer>
                <Header>{isBinaryBeer() ? "Kegs" : "Trackers"} Arrived/Departed</Header>
                <Value>
                  {data.trackersArrived}/{data.trackersDeparted}
                </Value>
                <Footer title={`${moment(filterDates.start).format(long_datetime)} - ${moment(filterDates.end).format(long_datetime)}`}>
                  {filterDates.isRelative
                    ? filterDates.label
                    : `From ${moment(filterDates.start).format(short_date)} to ${moment(filterDates.end).format(short_date)}`}
                </Footer>
              </ValueContainer>
            </ContentContainer>
          </BoxElement>
          {(data.alerts.resolved > 0 || data.alerts.unresolved > 0) && (
            <BoxElement>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                      <FaBell />
                    </IconContext.Provider>
                  </Icon>
                </IconContainer>
                <ValueContainer>
                  <Header>Alerts</Header>
                  <Value>
                    <span style={{ color: data.alerts.resolved === data.alerts.resolved + data.alerts.unresolved ? color.success[2] : color.danger[2] }}>
                      {data.alerts.resolved}
                    </span>
                    /{data.alerts.resolved + data.alerts.unresolved} Resolved
                  </Value>
                  <Footer title={`${moment(filterDates.start).format(long_datetime)} - ${moment(filterDates.end).format(long_datetime)}`}>
                    {filterDates.isRelative
                      ? filterDates.label
                      : `From ${moment(filterDates.start).format(short_date)} to ${moment(filterDates.end).format(short_date)}`}
                  </Footer>
                </ValueContainer>
              </ContentContainer>
            </BoxElement>
          )}
          {(data.allocations.allocated > 0 || data.allocations.misallocated > 0) && (
            <BoxElement>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                      <MdChecklist />
                    </IconContext.Provider>
                  </Icon>
                </IconContainer>
                <ValueContainer>
                  <Header>Allocations</Header>
                  <Value>
                    <span
                      style={{
                        color: data.allocations.allocated === data.allocations.allocated + data.allocations.misallocated ? color.success[2] : color.danger[2],
                      }}
                    >
                      {data.allocations.allocated}
                    </span>
                    /{data.allocations.allocated + data.allocations.misallocated} Allocated
                  </Value>
                  <Footer>Now</Footer>
                </ValueContainer>
              </ContentContainer>
            </BoxElement>
          )}
        </BoxesContainer>
      </LoadingContainer>

      {trackerListModalOpen && trackerList.length > 0 && (
        <TrackerListModal
          title={trackerListTitle + " List"}
          data={trackerList}
          columns={["id"]}
          size="sm"
          modalOpen={trackerListModalOpen}
          setModalOpen={setTrackerListModalOpen}
          onClose={() => {
            setTrackerList([]);
            setTrackerListTitle("");
          }}
        />
      )}
    </>
  );
};

export default PlaceMetrics;
