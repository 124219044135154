import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import moment from "moment";
import React, { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ThemeContext } from "styled-components";
import { useDebounce } from "use-debounce";
import { getPlaceTrackersDepartedTableSettings, placeTrackersDepartedTableDefaults, savePlaceTrackersDepartedTableSettings } from "../../services/localStorage";
import { fetchPlaceTrackersTable } from "../../services/placeTrackersTable";
import { isBinaryBeer } from "../../util/checkDomain";
import copyToClipboard from "../../util/copyToClipboard";
import downloadFile from "../../util/downloadFile";
import errToStr from "../../util/errToStr";
import { printFixedTemp, printTemp, printTempUnit } from "../../util/formatUnits";
import { kegOrTracker, kegsOrTrackers } from "../../util/kegOrTracker";
import sortTags from "../../util/sortTags";
import { getTableFilters } from "../../util/urlParamFilters";
import AssetTypeLabel from "../AssetTypeLabel";
import Badge from "../Badge";
import NewTable from "../NewTable";
import Tag from "../Tag";

type Tracker = {
  trackerId: string;
  trackerName: string;
  assetId: string;
  assetTypeName: string;
  assetTypeColour: string;
  assetTypeIcon: string;
  productId: number;
  temperature: number;
  orientation: string;
  beerName: string;
  dateFilledUnix: number;
  dateEmptiedUnix: number;
  onTap: boolean;
  dateOnTapUnix: number;
  freshness: number;
  inFridge: boolean;
  pickup: boolean;
  beerLogic: boolean;
  dateArrivedUnix: number;
  dateDepartedUnix: number;
  empty: boolean;
};

const PlaceTrackersDepartedTable: FC<any> = ({ id, filterDates, meta }) => {
  const { color, short_datetime, long_datetime } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<any>(0);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [sorting, setSorting] = useState<SortingState>([{ id: "dateDepartedUnix", desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnFiltersDebounced] = useDebounce(columnFilters, 200);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: getPlaceTrackersDepartedTableSettings() ? getPlaceTrackersDepartedTableSettings().pageSize : 10,
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const fetchData = () => {
    setDataLoading(true);
    setDataErr("");

    // Only use the end date if it's not now as the back-end will use the current time
    // for now and can return the data more quickly by using LatestSample in this case
    const dates = {
      start: filterDates && filterDates.start !== undefined ? filterDates.start.unix() : undefined,
      end: filterDates && filterDates.end !== undefined && !filterDates.isRelative ? filterDates.end.unix() : undefined,
    };

    fetchPlaceTrackersTable(source, id, dates, 2, { pageIndex, pageSize, orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
      .then((response) => {
        setData(response.data);
        setCount(response.count);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const fetchCsv = (download: boolean) => {
    setDataLoading(true);

    const dates = {
      start: filterDates && filterDates.start !== undefined ? filterDates.start.unix() : undefined,
      end: filterDates && filterDates.end !== undefined ? filterDates.end.unix() : moment().unix(),
    };

    fetchPlaceTrackersTable(source, id, dates, 2, { orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
      .then((response) => {
        if (download) {
          downloadFile(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            }),
            "text/csv;charset=utf-8",
            `${kegOrTracker("Kegs", "Trackers")} Departed List.csv`
          );
        } else {
          copyToClipboard(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            })
          );
          toast.info("Copied to Clipboard");
        }
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const formatDataToCsv = (data: any) => {
    const headers = [
      "Asset Type",
      "Tracker Id",
      "Asset Id",
      "Tracker Name",
      kegsOrTrackers("Keg Tags", "Tracker Tags"),
      ...(isBinaryBeer() ? ["Beer"] : []),
      ...(isBinaryBeer() ? ["Empty"] : []),
      ...(isBinaryBeer() ? ["In Fridge"] : []),
      ...(isBinaryBeer() ? ["On Tap"] : []),
      "Orientation",
      `Temperature (${printTempUnit()})`,
      ...(isBinaryBeer() ? ["Freshness (%)"] : []),
      "Date Arrived",
      ...(isBinaryBeer() ? ["Date Filled"] : []),
      ...(isBinaryBeer() ? ["Date On Tap"] : []),
      ...(isBinaryBeer() ? ["Date Emptied"] : []),
      "Date Departed",
      "Marked for Pickup",
    ];

    return [
      headers,
      ...data.map((row: any) => {
        const rows = [
          row.assetTypeName,
          row.trackerId,
          row.assetId,
          row.trackerName,
          row.trackerTags
            .sort(sortTags)
            .map((tag: any) => tag.name)
            .join(", "),
          ...(isBinaryBeer() ? [row.beerName] : []),
          ...(isBinaryBeer() ? [row.empty ? "True" : "False"] : []),
          ...(isBinaryBeer() ? [row.inFridge ? "True" : "False"] : []),
          ...(isBinaryBeer() ? [row.onTap ? "True" : "False"] : []),
          row.orientation === "S" ? "Sideways " : row.orientation === "D" ? "Upside Down " : "Upright ",
          printFixedTemp(row.temperature, 1),
          ...(isBinaryBeer() ? [row.freshness] : []),
          row.dateArrivedUnix !== undefined ? moment.unix(row.dateArrivedUnix).format(long_datetime) : "",
          ...(isBinaryBeer() ? [row.dateFilledUnix !== undefined ? moment.unix(row.dateFilledUnix).format(long_datetime) : ""] : []),
          ...(isBinaryBeer() ? [row.dateOnTapUnix !== undefined ? moment.unix(row.dateOnTapUnix).format(long_datetime) : ""] : []),
          ...(isBinaryBeer() ? [row.dateEmptiedUnix !== undefined ? moment.unix(row.dateEmptiedUnix).format(long_datetime) : ""] : []),
          row.dateDepartedUnix !== undefined ? moment.unix(row.dateDepartedUnix).format(long_datetime) : "",
          row.pickup ? "True" : "False",
        ];

        return rows;
      }, []),
    ];
  };

  const columns = React.useMemo<ColumnDef<Tracker>[]>(
    () => [
      {
        header: "Asset Type",
        accessorKey: "assetTypeName",
        cell: (props: any) => (
          <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
            <AssetTypeLabel name={props.getValue()} colour={props.row.original.assetTypeColour} icon={props.row.original.assetTypeIcon} />
          </div>
        ),
        meta: {
          filterType: "autoComplete",
          selectKey: "label",
          loadOptionsKey: "assettypes",
        },
        filterFn: undefined,
        size: 150,
      },
      {
        header: "Tracker ID",
        accessorKey: "trackerId",
        cell: (props: any) => {
          const url = kegOrTracker("kegs", "trackers");
          return (
            <Link title={props.getValue()} to={`/${url}/${props.getValue()}`}>
              {props.getValue()}
            </Link>
          );
        },
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 130,
      },
      {
        header: "Asset ID",
        accessorKey: "assetId",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 140,
      },
      {
        header: "Name",
        accessorKey: "trackerName",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 230,
      },
      {
        accessorKey: "trackerTags",
        cell: (props: any) =>
          props.row.original.trackerTags ? (
            props.row.original.trackerTags
              .sort(sortTags)
              .map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />)
          ) : (
            <></>
          ),
        header: kegsOrTrackers("Keg Tags", "Tracker Tags"),
        enableSorting: false,
        meta: {
          filterType: "autoComplete",
          filterKey: "trackerTags.name",
          loadOptionsKey: "trackerTags",
        },
        filterFn: undefined,
        size: 170,
      },
      ...(isBinaryBeer()
        ? [
            {
              accessorKey: "beerName",
              header: "Beer",
              cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
              meta: {
                filterType: "autoComplete",
                filterKey: "beerName",
                selectKey: "label",
                loadOptionsKey: "beers",
              },
              filterFn: undefined,
              size: 160,
            },
          ]
        : []),
      ...(isBinaryBeer()
        ? [
            {
              header: "Empty/Full",
              accessorKey: "empty",
              cell: (props: any) => (
                <div style={{ textAlign: "center" }}>
                  <Badge title={props.getValue() ? "Empty" : "Full"} background={props.getValue() ? color.danger_dark[2] : color.success_dark[2]}>
                    {props.getValue() ? "Empty" : "Full"}
                  </Badge>
                </div>
              ),
              meta: {
                filterType: "select",
                loadOptionsKey: "empty",
                selectKey: "value",
                selectOptions: [
                  { value: true, label: "Empty", colour: color.danger_dark[2] },
                  { value: false, label: "Full", colour: color.success_dark[2] },
                ],
              },
              filterFn: undefined,
              size: 145,
            },
          ]
        : []),
      ...(isBinaryBeer()
        ? [
            {
              header: "In Fridge",
              accessorKey: "inFridge",
              cell: (props: any) => (
                <div style={{ textAlign: "center" }}>
                  <Badge title={props.getValue() ? "True" : "False"} background={props.getValue() ? color.success_dark[2] : color.danger_dark[2]}>
                    {props.getValue() ? "True" : "False"}
                  </Badge>
                </div>
              ),
              meta: {
                filterType: "select",
                loadOptionsKey: "inFridge",
                selectKey: "value",
                selectOptions: [
                  { value: true, label: "True", colour: color.success_dark[2] },
                  { value: false, label: "False", colour: color.danger_dark[2] },
                ],
              },
              filterFn: undefined,
              size: 145,
            },
          ]
        : []),
      ...(isBinaryBeer() && meta.emptiesBeerKegs
        ? [
            {
              header: "On Tap",
              accessorKey: "onTap",
              cell: (props: any) => (
                <div style={{ textAlign: "center" }}>
                  <Badge title={props.getValue() ? "True" : "False"} background={props.getValue() ? color.success_dark[2] : color.danger_dark[2]}>
                    {props.getValue() ? "True" : "False"}
                  </Badge>
                </div>
              ),
              meta: {
                filterType: "select",
                loadOptionsKey: "onTap",
                selectKey: "value",
                selectOptions: [
                  { value: true, label: "True", colour: color.success_dark[2] },
                  { value: false, label: "False", colour: color.danger_dark[2] },
                ],
              },
              filterFn: undefined,
              size: 145,
            },
          ]
        : []),
      {
        accessorKey: "orientation",
        header: "Orientation",
        cell: (props: any) => {
          const orientation = props.getValue() === "S" ? "Sideways " : props.getValue() === "D" ? "Upside Down " : "Upright ";
          return <span title={orientation}>{orientation}</span>;
        },
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 150,
      },
      {
        accessorKey: "temperature",
        header: "Temperature",
        cell: (props: any) => {
          const temp = printTemp(props.getValue(), 1);
          return <span title={temp}>{temp}</span>;
        },
        meta: {
          filterType: "number",
        },
        filterFn: undefined,
        size: 150,
      },
      ...(isBinaryBeer()
        ? [
            {
              accessorKey: "freshness",
              header: "Freshness",
              cell: (props: any) => {
                const freshness = props.getValue() !== undefined ? `${props.getValue()}%` : "";
                return <span title={freshness}>{freshness}</span>;
              },
              meta: {
                filterType: "number",
              },
              filterFn: undefined,
              size: 190,
            },
          ]
        : []),
      {
        header: "Date Arrived",
        accessorKey: "dateArrivedUnix",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRangeUnix",
        },
        filterFn: undefined,
        size: 175,
      },
      ...(isBinaryBeer()
        ? [
            {
              header: "Date Filled",
              accessorKey: "dateFilledUnix",
              cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
              meta: {
                filterType: "dateRangeUnix",
              },
              filterFn: undefined,
              size: 175,
            },
          ]
        : []),
      ...(isBinaryBeer()
        ? [
            {
              header: "Date On Tap",
              accessorKey: "dateOnTapUnix",
              cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
              meta: {
                filterType: "dateRangeUnix",
              },
              filterFn: undefined,
              size: 175,
            },
          ]
        : []),
      ...(isBinaryBeer()
        ? [
            {
              header: "Date Emptied",
              accessorKey: "dateEmptiedUnix",
              cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
              meta: {
                filterType: "dateRangeUnix",
              },
              filterFn: undefined,
              size: 175,
            },
          ]
        : []),
      {
        header: "Date Departed",
        accessorKey: "dateDepartedUnix",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRangeUnix",
        },
        filterFn: undefined,
        size: 175,
      },
      {
        header: "Marked for Pickup",
        accessorKey: "pickup",
        cell: (props: any) => (
          <div style={{ textAlign: "center" }}>
            <Badge title={props.getValue() ? "True" : "False"} background={props.getValue() ? color.success_dark[2] : color.danger_dark[2]}>
              {props.getValue() ? "True" : "False"}
            </Badge>
          </div>
        ),
        meta: {
          filterType: "select",
          loadOptionsKey: "pickup",
          selectKey: "value",
          selectOptions: [
            { value: true, label: "True", colour: color.success_dark[2] },
            { value: false, label: "False", colour: color.danger_dark[2] },
          ],
        },
        filterFn: undefined,
        size: 190,
      },
    ],
    [color, meta]
  );

  return (
    <>
      <NewTable
        data={data}
        count={count}
        dataErr={dataErr}
        dataLoading={dataLoading}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        columnFiltersDebounced={columnFiltersDebounced}
        setColumnFilters={setColumnFilters}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        fetchData={fetchData}
        fetchDataDependencies={[filterDates]}
        fetchCsv={fetchCsv}
        defaultTableSettings={placeTrackersDepartedTableDefaults}
        getTableSettings={getPlaceTrackersDepartedTableSettings}
        saveTableSettings={savePlaceTrackersDepartedTableSettings}
        dataTypeName={kegsOrTrackers("Kegs", "Trackers")}
        emptyDataMsg={`Adjust date range to see ${kegsOrTrackers("kegs", "trackers")} that departed from here`}
      />
    </>
  );
};

export default PlaceTrackersDepartedTable;
